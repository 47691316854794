import {applyAttributesMixin} from '@/models/vue-mc/helpers';
import moment from 'moment';
import Model from './vue-mc/Model';
import Collection from './vue-mc/Collection';

export class Message extends Model {
    /**
     * Default attributes that define the "empty" state.
     *
     * @return {object}
     */
    defaults() {
        return {
            id: null,
            body: '',
            conversationId: null,
        };
    }

    /**
     * Options of this model.
     *
     * @return {object}
     */
    options() {
        return {
            endpoint: 'messages',
        };
    }

    transformations() {
        return {
            createdAt: moment.utc,
        };
    }
};

export const Messages = applyAttributesMixin(class Messages extends Collection {
    /**
     * Options of this collection.
     *
     * @return {object}
     */
    options() {
        return {
            model: Message,
        };
    }

    /**
     * @returns {Object} Query parameters that will be appended to the `fetch` URL.
     */
    getFetchQuery() {
        let query = super.getFetchQuery();

        // Retrieve messages before the `beforeId`.
        if (this.beforeId) query.before_id = this.beforeId;

        return query;
    }
});
