<template>
    <div class="d-flex align-items-center">
        <ow-image
            :image="receiver.image"
            class="flex-shrink-0 rounded-circle mr-3"
        />

        <div class="text-truncate">
            <h6>{{ receiver.name }}</h6>

            <span class="text-muted">
                {{ _.get(conversation.messages[0], 'body') }}
            </span>
        </div>
    </div>
</template>

<script>
import Image from '@/library/Image';
import OwImage from '@/components/common/OwImage';
import {Conversation} from '@/models/Conversation';

export default {
    name: 'ConversationListItem',
    components: {OwImage},
    props: {
        conversation: {
            type: Conversation,
            required: true,
        },
    },
    computed: {
        /**
         * The other participant of the conversation that is not the current user.
         *
         * @return {User|undefined}
         */
        receiver() {
            return this.conversation.users.find(user => user.id != this.$me.id);
        },
    },
};
</script>
