<template>
    <b-row class="h-100 flex-column">
        <b-col cols="auto">
            <div class="flex-shrink-0 d-flex align-items-center mb-4">
                <ow-image
                    class="rounded-circle mr-3"
                    :image="receiver.image"
                />

                <div class="text-truncate">
                    <h6>{{ receiver.name }}</h6>
                </div>
            </div>
        </b-col>

        <b-col class="overflow-hidden">
            <div
                ref="messagesContainer"
                v-infinite-scroll.up="fetchCollection"
                class="h-100 overflow-y-auto d-flex flex-column-reverse px-3"
            >
                <!-- Instruction to write first message -->
                <div
                    v-if="!collection.loading && collection.isEmpty()"
                    class="h-100 d-flex flex-column justify-content-center align-items-center"
                >
                    <h5>{{ $t('conversations.messages.writeFirstMessageTo', [receiver.name]) }}</h5>
                </div>

                <message-bubble
                    v-for="(m, index) in collection.models"
                    v-else
                    :key="m.id"
                    :message="m"
                    :show-date="showMessageDate(index)"
                    :show-time="showMessageTime(index)"
                    class="mb-3"
                />

                <wait-for-resource :resource="collection" />
            </div>
        </b-col>

        <b-col cols="auto">
            <b-input-group class="rounded bg-gray-100">
                <b-input
                    v-model="message.body"
                    class="border-0 px-3 small bg-transparent"
                    :placeholder="$t('common.actions.typeMessage')"
                    @keyup.enter="sendMessage()"
                />

                <b-input-group-append>
                    <btn-resource
                        :variant="message.changed() ? 'primary' : ''"
                        class="btn-square btn-sm rounded-circle m-1 mx-3"
                        :resource="message"
                        state-icons-only
                        @click="sendMessage()"
                    >
                        <fa icon="long-arrow-alt-up" />
                    </btn-resource>
                </b-input-group-append>
            </b-input-group>

            <small
                v-if="message.firstErrorMessage"
                class="text-danger"
            >
                {{ message.firstErrorMessage }}
            </small>
        </b-col>
    </b-row>
</template>

<script>
import Image from '@/library/Image';
import MessageBubble from './MessageBubble';
import OwImage from '@/components/common/OwImage';
import {Conversation} from '@/models/Conversation';
import {Message, Messages} from '@/models/Message';
import QueryableCollection from '@/components/common/mixins/QueryableCollection';

export default {
    name: 'ConversationRoom',
    components: {OwImage, MessageBubble},
    mixins: [QueryableCollection],
    props: {
        conversation: {
            type: Conversation,
            required: true,
        },
    },
    data: function() {
        return {
            /**
             * The new message being typed.
             */
            message: null,
            /**
             * The conversation's messages.
             */
            collection: new Messages(
                this.conversation.messages,
                {routePrefix: this.conversation.getFetchURL()},
            ),
            /**
             * The timer used when periodically fetching new messages.
             */
            fetchNewMessagesTimer: null,
        };
    },
    computed: {
        /**
         * The other participant of the conversation that is not the current user.
         *
         * @return {User|undefined}
         */
        receiver() {
            return this.conversation.users.find(user => user.id != this.$me.id);
        },
        scrollingElement() {
            return this.$refs.messagesContainer;
        },
    },
    watch: {
        // Emit the newest message everytime it's updated.
        'collection.models': function() {
            this.$emit('newest-message', this.collection.first());
        },
    },
    created() {
        this.newMessage();
    },
    async mounted() {
        // Prepare to fetch messages older than what we already have.
        this.collection.beforeId = this._.get(this.collection.last(), 'id');

        // Do not specify `scrollDirection = 'up'` because we are using
        // `flex-direction: column-reverse` in the `.message-bubbles-container-wrapper`.
        await this.fetchCollectionUntilScrollable();
    },
    activated() {
        this.periodicallyFetchNewMessages();
    },
    deactivated() {
        clearInterval(this.fetchNewMessagesTimer);
    },
    methods: {
        newMessage() {
            this.message = new Message({
                conversationId: this.conversation.id,
            });
        },
        async sendMessage() {
            await this.message.save();

            // Insert the sent message to the conversation.
            if (this.message.id) {
                this.collection.models.unshift(this.message);
            }

            this.newMessage();
        },
        periodicallyFetchNewMessages() {
            const helper = this.collection.clone();

            // Fetch new messages every 5 seconds.
            this.fetchNewMessagesTimer = setInterval(async() => {
                const latestId = this._.get(this.collection.first(), 'id', 0);

                await helper.fetch();

                const newMessages = helper.where((m) => m.id > latestId);

                if (newMessages.length > 0) {
                    this.collection.add(newMessages);

                    // Order the messages by id, so latest is always at the bottom.
                    this.collection.orderBy('id', 'desc');
                }
            }, 5000);
        },
        /**
         * Determine if message date should be shown or not.
         */
        showMessageDate(index) {
            if (index === this.collection.models.length - 1) return true;

            const currentMessage = this.collection.models[index];
            const previousMessage = this.collection.models[index + 1];

            // Only show date if the previous message is not in the same day.
            return !previousMessage.createdAt.isSame(currentMessage.createdAt, 'day');
        },
        /**
         * Determine if message timestamp should be shown or not.
         */
        showMessageTime(index) {
            if (index === 0) return true;

            const currentMessage = this.collection.models[index];
            const nextMessage = this.collection.models[index - 1];

            // Only show time if the next message is not in the same minute.
            return !nextMessage.createdAt.isSame(currentMessage.createdAt, 'minute');
        },
    },
};
</script>
