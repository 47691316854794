<template>
    <div class="card h-100 card-conversation">
        <portal to="layout-default-header">
            <h1>{{ $t('conversations.index.title') }}</h1>
        </portal>

        <b-row
            class="h-100"
            no-gutters
        >
            <b-col
                cols="4"
                class="h-100 border-right"
            >
                <b-row
                    class="flex-column h-100"
                    no-gutters
                >
                    <b-col
                        class="p-4 border-bottom"
                        cols="auto"
                    >
                        <input-search v-model="collection.searchQuery" />
                    </b-col>

                    <b-col class="overflow-y-auto">
                        <!-- Conversation List -->
                        <conversation-list-item
                            v-for="conversation in collection.models"
                            :key="conversation.id"
                            :conversation="conversation"
                            class="conversation-li cursor-pointer py-3 px-4"
                            :class="{active: conversation.id === _.get(shownConversation, 'id')}"
                            @click.native="showConversation(conversation)"
                        />

                        <wait-for-resource
                            :resource="collection"
                            class="my-4 px-5 text-center small text-muted"
                        >
                            {{ $t('clients.messages.clientConversationWillAppear') }}
                        </wait-for-resource>
                    </b-col>
                </b-row>
            </b-col>

            <b-col cols="8">
                <keep-alive>
                    <conversation-room
                        v-if="!collection.isEmpty() && shownConversation"
                        :key="_.get(shownConversation, 'id')"
                        :conversation="shownConversation"
                        class="p-4"
                        @newest-message="updateNewestMessage(shownConversation, $event)"
                    />

                    <div
                        v-else
                        class="h-100 d-flex flex-column align-items-center justify-content-center"
                    >
                        <h5>{{ $t('clients.messages.inviteClientsAndChat') }}</h5>

                        <b-link
                            class="text-primary mt-2"
                            :to="{...$me.getLocation('members.index'), query: {invite: true}}"
                        >
                            {{ $t('clients.actions.inviteClients') }}
                        </b-link>
                    </div>
                </keep-alive>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {Conversations} from '@/models/Conversation';
import InputSearch from '@/components/common/form/InputSearch';
import ConversationRoom from '@/components/conversations/Room';
import ConversationListItem from '@/components/conversations/ListItem';
import QueryableCollection from '@/components/common/mixins/QueryableCollection';

export default {
    name: 'ConversationsIndex',
    components: {InputSearch, ConversationListItem, ConversationRoom},
    mixins: [QueryableCollection],
    props: {
        /**
         * The id of the currently shown conversation.
         */
        id: {
            type: [Number, String],
            default: undefined,
        },
    },
    data: function() {
        return {
            collection: new Conversations([], {
                routePrefix: this.$me.getFetchURL(),
            }),
        };
    },
    computed: {
        /**
         * The currently shown conversation.
         *
         * @returns {Conversation|undefined}
         */
        shownConversation() {
            if (this.id) {
                return this.collection.find(c => c.id === parseInt(this.id));
            }

            if (!this.collection.isEmpty()) {
                return this.collection.first();
            }

            return undefined;
        },
    },
    async mounted() {
        await this.fetchCollection();
    },
    methods: {
        showConversation(conversation) {
            if (parseInt(this.id) != conversation.id) {
                this.$router.push({params: {id: conversation.id}});
            }
        },
        updateNewestMessage(conversation, message) {
            conversation.messages.unshift(message);
        },
    },
};
</script>

<style lang="scss" scoped>
.conversation-li {
    &.active,
    &:hover {
        background-color: $light;
    }
}

.card-conversation {
    min-height: 500px;
    max-height: calc(100vh - 18rem);
}
</style>
