import {applyAttributesMixin} from '@/models/vue-mc/helpers';
import {Users} from './User';
import Model from './vue-mc/Model';
import Collection from './vue-mc/Collection';

export class Conversation extends Model {
    /**
     * Default attributes that define the "empty" state.
     *
     * @return {object}
     */
    defaults() {
        return {
            id: null,
        };
    }

    /**
     * Options of this model.
     *
     * @return {object}
     */
    options() {
        return {
            endpoint: 'conversations',
        };
    }

    /**
     * Mapping of model attributes to the class it should be transformed into.
     *
     * @returns {Record<string, Constructor>}
     */
    transformations() {
        return {
            users: Users,
        };
    }
};

export const Conversations = applyAttributesMixin(class Conversations extends Collection {
    /**
     * Options of this collection.
     *
     * @return {object}
     */
    options() {
        return {
            model: Conversation,
        };
    }
});
