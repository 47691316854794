<template>
    <div :class="isMine ? 'text-right' : 'text-left'">
        <div
            v-if="showDate"
            class="small text-center mb-2"
        >
            {{ message.createdAt.format('LL') }}
        </div>

        <div
            class="message-bubble d-inline-block rounded p-3"
            :class="isMine ? 'bg-primary text-white' : 'bg-light'"
        >
            {{ message.body }}
        </div>

        <div
            v-if="showTime"
            class="small text-muted mt-1"
        >
            {{ message.createdAt.format('LT') }}
        </div>
    </div>
</template>

<script>
import {Message} from '@/models/Message';

export default {
    name: 'MessageBubble',
    props: {
        message: {
            type: Message,
            required: true,
        },
        showDate: {
            type: Boolean,
            default: false,
        },
        showTime: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        /**
         * A boolean that tells whether the current user is the message's sender.
         */
        isMine() {
            return this.message.userId === this.$me.id;
        },
    },
};
</script>

<style lang="scss" scoped>
.message-bubble {
    max-width: 25rem;
}
</style>
